import { createRoute } from "@/assets/data/produce/classify";

const productList = [
    createRoute("mkt", "agricultural", "农业设备", "goods"),
    createRoute("mkt", "part", "BOOM库", "part", "manage_external_inventory"),
    createRoute("mkt", "other", "研发&维保", "tool"),
    createRoute("mkt", "extend", "其它产品", "goods-extend"),
    createRoute("mkt", "oem", "OEM产品", "oem-goods"),
    createRoute("mkt", "fertilizer", "肥料护理", "indicator"),
    createRoute("mkt", "solutions", "室内方案", "produce-task-testing"),
    createRoute("mkt", "substrateSeedling", "基质种苗", "detection"),
    createRoute("mkt", "packaging", "包装设计", "category"),
    createRoute("mkt", "crop", "作物保护", "plant"),
    createRoute("mkt", "service", "安装服务", "titan"),
];

export default [
    {
        path: "/mkt",
        name: "mkt__root__",
        redirect: {
            name: "mkt-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "mkt",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "mkt-index",
                component: () => import("@/views/fms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/mkt/customer",
        name: "mkt-customer",
        meta: {
            title: "客户管理",
            icon: "enterprise",
            parent: "mkt",
            permission: "manage_customer",
        },
        // hidden: true,
        component: () => import("@/layouts/default.vue"),
        redirect: "/mkt/customer/organization",
        children: [
            {
                path: "organization",
                name: "mkt-enterprise-organization",
                component: () => import("@/views/omp/enterprise/organization.vue"),
                meta: {
                    title: "企业管理",
                    icon: "organization",
                },
            },
            {
                path: "dashboard",
                name: "mkt-enterprise-dashboard",
                component: () => import("@/views/omp/enterprise/dashboard.vue"),
                meta: {
                    title: "园区管理",
                    icon: "dashboard_mng",
                },
            },
            {
                path: "user",
                name: "mkt-account-user",
                component: () => import("@/views/omp/account/user.vue"),
                meta: {
                    title: "账号管理",
                    icon: "users",
                },
            },
            {
                path: "other",
                name: "mkt-account-other",
                component: () => import("@/views/omp/account/other-user.vue"),
                meta: {
                    title: "散户管理",
                    icon: "user",
                },
            },
            {
                path: "address",
                name: "mkt-customer-address",
                component: () => import("@/views/fms/customer/address.vue"),
                meta: {
                    title: "地址管理",
                    icon: "address-mng",
                },
            },
        ],
    },
    {
        path: "/mkt/order",
        name: "mkt-order",
        meta: {
            title: "合同订单",
            icon: "invoice",
            parent: "mkt",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/mkt/order/intention",
        children: [
            {
                path: "project",
                name: "mkt-order-project",
                component: () => import("@/views/fms/order/project.vue"),
                meta: {
                    title: "项目管理",
                    icon: "project",
                    affix: false,
                    permission: "manage_order_setting",
                },
            },
            {
                path: "project-detail/:id(\\d+)?",
                name: "mkt-order-project-detail",
                component: () => import("@/views/fms/order/project-detail.vue"),
                hidden: true,
                meta: {
                    title: "项目详情",
                    icon: "project",
                    affix: false,
                    activeMenu: "/mkt/order/project",
                },
            },
            {
                path: "intention",
                name: "mkt-order-intention",
                component: () => import("@/views/fms/order/intention"),
                meta: {
                    title: "意向订单",
                    icon: "intention1",
                    affix: false,
                    keepAlive: true,
                    permission: "manage_order",
                },
            },
            {
                path: "intention_edit/:id(\\d+)?",
                name: "mkt-order-intention-edit",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-edit.vue"),
                meta: {
                    title: "订单编辑",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/mkt/order/intention",
                    permission: "manage_order",
                },
            },
            {
                path: "intention_detail/:id(\\d+)?",
                name: "mkt-order-intention-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/mkt/order/intention",
                    permission: "manage_order",
                },
            },
            {
                path: "intention_sign/:id(\\d+)?",
                name: "mkt-order-intention-sign",
                hidden: true,
                component: () => import("@/views/fms/order/components/intention-sign.vue"),
                meta: {
                    title: "开始签约",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/mkt/order/intention",
                    permission: "manage_order",
                },
            },
            {
                path: "sign",
                name: "mkt-order-sign",
                component: () => import("@/views/fms/order/sign"),
                meta: {
                    title: "签约订单",
                    icon: "sign",
                    affix: false,
                    key: "sign",
                    keepAlive: true,
                    permission: "manage_order_sold",
                },
            },
            {
                path: "purchase",
                name: "mkt-order-purchase",
                component: () => import("@/views/fms/order/sign"),
                meta: {
                    title: "采购订单",
                    icon: "purchase",
                    affix: false,
                    key: "purchase",
                    keepAlive: true,
                    permission: "manage_order_purchase",
                },
            },
            {
                path: "sign_create",
                name: "mkt-entry-sign-add",
                component: () => import("@/views/fms/order/components/entry-order.vue"),
                meta: {
                    title: "录入订单",
                    activeMenu: "/mkt/order/sign",
                    permission: "manage_order",
                },
                hidden: true,
            },
            {
                path: "sign_edit/:id(\\d+)",
                name: "mkt-entry-sign-edit",
                component: () => import("@/views/fms/order/components/entry-order.vue"),
                meta: {
                    title: "编辑订单",
                    activeMenu: "/mkt/order/sign",
                    permission: "manage_order",
                },
                hidden: true,
            },
            {
                path: "sign_detail/:id(\\d+)?",
                name: "mkt-order-sign-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/sign-detail.vue"),
                meta: {
                    title: "签约详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/mkt/order/sign",
                    permission: "manage_order",
                },
            },
            {
                path: "start_produce/:order_id(\\d+)",
                name: "mkt-order-start-produce",
                component: () => import("@/views/fms/order/components/start-produce.vue"),
                meta: {
                    title: "开始生产",
                },
                hidden: true,
            },
            {
                path: "deliver_detail/:order_id(\\d+)",
                name: "mkt-order-deliver-detail",
                component: () => import("@/views/fms/order/components/order-deliver-detail.vue"),
                meta: {
                    title: "发货",
                    keepAlive: true,
                    affix: false,
                },
                hidden: true,
            },
            {
                path: "install/check_info/:order_id(\\d+)/:install_id(\\d+)?",
                name: "mkt-order-install-check-info",
                component: () => import("@/views/fms/order/components/install/check-info.vue"),
                meta: {
                    title: "信息确认",
                    activeMenu: "/mkt/order/sign",
                },
                hidden: true,
            },
            {
                path: "install/prepare_node/:order_id(\\d+)/:check_id(\\d+)/:install_id(\\d+)?",
                name: "mkt-order-install-prepare-node",
                component: () => import("@/views/fms/order/components/install/prepare-node.vue"),
                meta: {
                    title: "配置节点",
                    activeMenu: "/mkt/order/sign",
                },
                hidden: true,
            },
            {
                path: "install/appointment/:order_id(\\d+)/:check_id(\\d+)/:install_id(\\d+)?",
                name: "mkt-order-install-appointment",
                component: () => import("@/views/fms/order/components/install/appointment.vue"),
                meta: {
                    title: "预约安装",
                    activeMenu: "/mkt/order/sign",
                },
                hidden: true,
            },
            {
                path: "start_debug/:order_id(\\d+)",
                name: "mkt-order-start-debug",
                component: () => import("@/views/fms/order/components/start-debug.vue"),
                meta: {
                    title: "开始调试",
                },
                hidden: true,
            },
            {
                path: "acceptance_form/:order_id(\\d+)",
                name: "mkt-order-acceptance-form",
                component: () => import("@/views/fms/order/components/acceptance-form.vue"),
                meta: {
                    title: "生成验收单",
                    activeMenu: "/mkt/order/sign",
                },
                hidden: true,
            },
            {
                path: "contact",
                name: "mkt-contact",
                component: () => import("@/views/cms/service/contact"),
                meta: {
                    title: "联系咨询",
                    icon: "phone-call",
                    affix: false,
                    permission: "manage_order_sold",
                },
            },
            {
                path: "quotation",
                name: "mkt-quotation",
                component: () => import("@/views/cms/service/quotation"),
                meta: {
                    title: "报价咨询",
                    icon: "discount",
                    affix: false,
                    permission: "manage_order_sold",
                },
            },
            {
                path: "template",
                name: "mkt-order-promise-template",
                component: () => import("@/views/fms/order/promise-template.vue"),
                meta: {
                    title: "文案模板",
                    icon: "setting",
                    affix: false,
                    permission: "manage_order_setting",
                },
            },
        ],
    },
    {
        path: "/mkt/oem",
        name: "mkt-oem",
        meta: {
            title: "OEM管理",
            icon: "oem",
            parent: "mkt",
            affix: false,
            permission: "manage_oem",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/mkt/oem/list",
        children: [
            {
                path: "oem-card",
                name: "mkt-bill-oem-card",
                component: () => import("@/views/fms/bill/oem-card.vue"),
                meta: {
                    title: "收款卡号",
                    icon: "card",
                    affix: false,
                },
            },
            {
                path: "oem-card-log",
                name: "mkt-bill-oem-card-log",
                component: () => import("@/views/fms/bill/oem-card-log.vue"),
                meta: {
                    title: "收款日志",
                    icon: "card",
                    affix: false,
                },
            },
            {
                path: "list",
                name: "mkt-oem-list",
                component: () => import("@/views/fms/order/oem"),
                meta: {
                    title: "OEM订单",
                    icon: "oem",
                    affix: false,
                },
            },
            {
                path: "detail/:id(\\d+)?",
                name: "mkt-oem-detail",
                hidden: true,
                component: () => import("@/views/fms/order/components/oem-detail.vue"),
                meta: {
                    title: "订单详情",
                    icon: "oem",
                    affix: false,
                    activeMenu: "/mkt/oem/list",
                },
            },
            {
                path: "inventory",
                name: "mkt-oem-inventory",
                component: () => import("@/views/fms/produce/oem_inventory/index.vue"),
                meta: {
                    title: "客户库存",
                    icon: "oem-goods",
                    parent: "mkt",
                },
            },
        ],
    },
    {
        path: "/mkt/mall",
        name: "mkt-mall",
        meta: {
            title: "产品仓库",
            icon: "product",
            parent: "mkt",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/mkt/mall/goods",
        children: [
            {
                path: "inv-adjust",
                name: "mkt-produce-inv-adjust",
                component: () => import("@/views/fms/produce/inv_adjust/index.vue"),
                meta: {
                    title: "库存申请",
                    icon: "inv-adjust",
                    affix: false,
                },
            },
            ...productList,
            {
                path: "good/:id(\\d+)?",
                name: "mkt-mall-goods-form",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_form.vue"),
                meta: {
                    title: "产品编辑",
                    icon: "ai",
                    affix: false,
                },
            },
            {
                path: "good_detail/:id(\\d+)?",
                name: "mkt-mall-goods-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_detail.vue"),
                meta: {
                    title: "产品详情",
                    icon: "ai",
                    affix: false,
                },
            },
        ],
    },
];
