import { createRoute } from "@/assets/data/produce/classify";

const productList = [
    createRoute("prod", "agricultural", "农业设备", "goods"),
    createRoute("prod", "part", "BOOM库", "part", "manage_external_inventory"),
    createRoute("prod", "other", "研发&维保", "tool"),
    createRoute("prod", "extend", "其它产品", "goods-extend"),
    createRoute("prod", "oem", "OEM产品", "oem-goods"),
    createRoute("prod", "fertilizer", "肥料护理", "indicator"),
    createRoute("prod", "solutions", "室内方案", "produce-task-testing"),
    createRoute("prod", "substrateSeedling", "基质种苗", "detection"),
    createRoute("prod", "packaging", "包装设计", "category"),
    createRoute("prod", "crop", "作物保护", "plant"),
    createRoute("prod", "service", "安装服务", "titan"),
];

export default [
    {
        path: "/prod",
        name: "prod__root__",
        redirect: {
            name: "prod-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "prod",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "prod-index",
                component: () => import("@/views/fms/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/prod/mall",
        name: "prod-mall",
        meta: {
            title: "产品管理",
            icon: "product",
            parent: "prod",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/mall/goods",
        children: [
            ...productList,

            {
                path: "good/:id(\\d+)?",
                name: "prod-mall-goods-form",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_form.vue"),
                meta: {
                    title: "产品编辑",
                    icon: "ai",
                    affix: false,
                },
            },
            {
                path: "good_detail/:id(\\d+)?",
                name: "prod-mall-goods-detail",
                hidden: true,
                component: () => import("@/views/fms/mall/goods_detail.vue"),
                meta: {
                    title: "产品详情",
                    icon: "ai",
                    affix: false,
                },
            },
            {
                path: "category",
                name: "prod-mall-category",
                component: () => import("@/views/fms/mall/goods_category.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                    permission: "manage_product",
                },
            },
        ],
    },
    {
        path: "/prod/produce",
        name: "prod-produce",
        meta: {
            title: "生产管理",
            icon: "product",
            parent: "prod",
            permission: "manage_product_make",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/produce/task",
        children: [
            {
                path: "task",
                name: "prod-produce-task",
                component: () => import("@/views/fms/produce/task/index.vue"),
                meta: {
                    title: "生产任务",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "task-log-template",
                name: "prod-produce-task-log-template",
                component: () => import("@/views/fms/produce/task_log_template/index.vue"),
                meta: {
                    title: "流程模版",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "task-edit/:id(\\d+)?",
                name: "prod-produce-task-edit",
                component: () => import("@/views/fms/produce/task/form.vue"),
                meta: {
                    title: "编辑生产任务",
                    activeMenu: "/prod/produce/task",
                },
                hidden: true,
            },
            {
                path: "task-detail/:id(\\d+)",
                name: "prod-produce-task-detail",
                component: () => import("@/views/fms/produce/task/detail.vue"),
                meta: {
                    title: "生产任务详情",
                    activeMenu: "/prod/produce/task",
                },
                hidden: true,
            },

            {
                path: "plan",
                name: "prod-produce-plan",
                component: () => import("@/views/fms/produce/plan/index.vue"),
                meta: {
                    title: "生产规划",
                    icon: "plan",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/prod/inventory",
        name: "prod-inventory",
        meta: {
            title: "库存管理",
            icon: "stock-record",
            parent: "prod",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/inventory/goods",
        children: [
            {
                path: "inv-adjust",
                name: "prod-produce-inv-adjust",
                component: () => import("@/views/fms/produce/inv_adjust/index.vue"),
                meta: {
                    title: "库存申请",
                    icon: "inv-adjust",
                    affix: false,
                },
            },
            {
                path: "device",
                name: "prod-inventory-device",
                component: () => import("@/views/fms/device"),
                meta: {
                    title: "设备入库",
                    icon: "device",
                    affix: false,
                    permission: "manage_inventory",
                },
            },
            {
                path: "goods",
                name: "prod-produce-inventory",
                component: () => import("@/views/fms/produce/inventory/index.vue"),
                meta: {
                    title: "库存管理",
                    icon: "raw-material",
                    affix: false,
                    permission: "manage_inventory",
                },
            },
            {
                path: "inventory_detail/:id(\\d+)?",
                name: "prod-mall-inventory-detail",
                hidden: true,
                component: () => import("@/views/fms/produce/inventory/detail.vue"),
                meta: {
                    title: "库存详情",
                    icon: "ai",
                    affix: false,
                    activeMenu: "/prod/produce/inventory",
                },
            },
            {
                path: "stock-record",
                name: "prod-mall-stock-record",
                component: () => import("@/views/fms/mall/stock_record.vue"),
                meta: {
                    title: "流转记录",
                    icon: "exchange",
                    affix: false,
                    permission: "manage_inventory",
                },
            },
            {
                path: "stat",
                name: "prod-produce-stat",
                component: () => import("@/views/fms/produce/stat/index.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                    permission: "manage_inventory",
                },
            },
        ],
    },
    {
        path: "/prod/general",
        name: "prod-general",
        meta: {
            title: "套餐管理",
            icon: "order-collection",
            parent: "prod",
            permission: "manage_general",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/bill/order-collection",
        children: [
            {
                path: "sim",
                name: "prod-general-sim",
                component: () => import("@/views/fms/general/sim.vue"),
                meta: {
                    title: "流量套餐",
                    icon: "expansion",
                    affix: false,
                },
            },
            {
                path: "package",
                name: "prod-general-titan-package",
                component: () => import("@/views/fms/general/titan-package.vue"),
                meta: {
                    title: "泰坦套餐",
                    icon: "expansion",
                    affix: false,
                },
            },
            {
                path: "expansion",
                name: "prod-general-expansion-system",
                component: () => import("@/views/fms/general/expansion-system.vue"),
                meta: {
                    title: "拓展系统",
                    icon: "expansion",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/prod/dbmanage",
        name: "prod-dbmanage",
        meta: {
            title: "数据管理",
            icon: "data",
            parent: "prod",
            permission: "manage_data",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/customer/organization",
        children: [
            {
                path: "album",
                name: "prod-dbmanage-album",
                component: () => import("@/views/fms/dbmanage/album.vue"),
                meta: {
                    title: "相册数据",
                    icon: "album",
                },
            },
            {
                path: "device",
                name: "prod-dbmanage-device",
                component: () => import("@/views/fms/dbmanage/device.vue"),
                meta: {
                    title: "设备数据",
                    icon: "device",
                },
            },
        ],
    },
    {
        path: "/prod/after-sales",
        name: "prod-after-sales",
        meta: {
            title: "售后中心",
            icon: "chatbot",
            parent: "prod",
            permission: "manage_after_sale",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/after-sales/workflows",
        children: [
            {
                path: "workflows",
                name: "prod-workflows",
                component: () => import("@/views/fms/after-sales/workflows.vue"),
                meta: {
                    title: "售后工单",
                    icon: "workflows",
                    affix: false,
                },
            },

            {
                path: "create",
                name: "prod-create-workflow",
                component: () => import("@/views/fms/after-sales/components/workflow-form.vue"),
                meta: {
                    title: "创建工单",
                    activeMenu: "/prod/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "prod-edit-workflow",
                component: () => import("@/views/fms/after-sales/components/workflow-form.vue"),
                meta: {
                    title: "编辑工单",
                    activeMenu: "/prod/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "prod-workflow-detail",
                component: () => import("@/views/fms/after-sales/components/workflow-detail.vue"),
                meta: {
                    title: "工单详情",
                    activeMenu: "/prod/after-sales/workflows",
                },
                hidden: true,
            },
            {
                path: "analysis",
                name: "prod-after-sales-analysis",
                component: () => import("@/views/fms/after-sales/workflows-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
            {
                path: "types",
                name: "prod-after-sales-types",
                component: () => import("@/views/fms/after-sales/types.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                    permission: "fms_manage_after_sales",
                },
            },
            {
                path: "template",
                name: "prod-after-sales-template",
                component: () => import("@/views/fms/after-sales/workflows-template.vue"),
                meta: {
                    title: "处理模板",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "ewx-phone",
                name: "prod-enterprise-wechat-phone",
                component: () => import("@/views/fms/after-sales/ewx-phone.vue"),
                meta: {
                    title: "企微电话",
                    icon: "wechat-phone",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/prod/repair",
        name: "prod-repair",
        meta: {
            title: "安装维修",
            icon: "maintenance",
            parent: "prod",
            permission: "manage_repair",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/prod/repair/install",
        children: [
            {
                path: "install",
                name: "prod-repair-install",
                component: () => import("@/views/fms/repair/install-workflows.vue"),
                meta: {
                    title: "安装工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "install-detail/:id(\\d+)",
                name: "prod-repair-install-detail",
                component: () => import("@/views/fms/repair/components/install-detail.vue"),
                meta: {
                    title: "安装任务详情",
                    activeMenu: "/prod/repair/install",
                },
                hidden: true,
            },
            {
                path: "install-edit/:id(\\d+)",
                name: "prod-repair-install-edit",
                component: () => import("@/views/fms/repair/components/install-edit.vue"),
                meta: {
                    title: "安装任务编辑",
                    activeMenu: "/prod/repair/install",
                },
                hidden: true,
            },
            {
                path: "task",
                name: "prod-repair-task",
                component: () => import("@/views/fms/repair/workflows.vue"),
                meta: {
                    title: "维修工单",
                    icon: "workflows",
                    affix: false,
                },
            },
            {
                path: "create",
                name: "prod-repair-task-create",
                component: () => import("@/views/fms/repair/components/workflow-form.vue"),
                meta: {
                    title: "创建维修任务",
                    activeMenu: "/prod/repair/task",
                },
                hidden: true,
            },
            {
                path: "edit/:id(\\d+)",
                name: "prod-repair-task-edit",
                component: () => import("@/views/fms/repair/components/workflow-form.vue"),
                meta: {
                    title: "编辑维修任务",
                    activeMenu: "/prod/repair/task",
                },
                hidden: true,
            },
            {
                path: "detail/:id(\\d+)",
                name: "prod-repair-task-detail",
                component: () => import("@/views/fms/repair/components/workflow-detail.vue"),
                meta: {
                    title: "维修任务详情",
                    activeMenu: "/prod/repair/task",
                },
                hidden: true,
            },
            {
                path: "analysis",
                name: "prod-repair-analysis",
                component: () => import("@/views/fms/repair/workflows-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "workflows-stat",
                    affix: false,
                },
            },
            {
                path: "types",
                name: "prod-repair-types",
                component: () => import("@/views/fms/repair/types.vue"),
                meta: {
                    title: "类型配置",
                    icon: "setting",
                    affix: false,
                },
            },
            {
                path: "template",
                name: "prod-repair-template",
                component: () => import("@/views/fms/repair/workflows-template.vue"),
                meta: {
                    title: "处理模板",
                    icon: "setting",
                    affix: false,
                },
            },
        ],
    },
];
