export const createRoute = (router, path, title, icon, permission) => ({
    path,
    name: `${router}-mall-${path}`,
    component: () => import("@/views/fms/mall/goods.vue"),
    meta: {
        title,
        icon,
        key: path,
        affix: false,
        keepAlive: true,
        permission,
    },
});
