import device_type from "@/assets/data/device/device_type.json";

const deviceComp = () => import("@/views/omp/device/device.vue");

const deviceRoutes = device_type.map((item) => {
    const route = {
        path: `${item.key}`,
        name: `dev-device-${item.key}`,
        component: deviceComp,
        meta: {
            title: item.name,
            icon: `device_${item.key}`,
            typeId: item.id,
        },
    };
    return route;
});

export default [
    {
        path: "/dev",
        name: "dev__root__",
        redirect: {
            name: "dev-index",
        },
        component: () => import("@/layouts/default.vue"),
        meta: {
            parent: "dev",
            title: "",
        },
        children: [
            {
                path: "index",
                name: "dev-index",
                component: () => import("@/views/dev/index.vue"),
                meta: {
                    title: "控制面板",
                    icon: "index",
                    affix: true,
                },
            },
        ],
    },
    {
        path: "/dev/conf",
        name: "dev-conf",
        meta: {
            title: "基础配置",
            icon: "atom",
            parent: "dev",
            group: 512,
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/dev/conf/global",
        children: [
            {
                path: "global",
                name: "dev-conf-global",
                component: () => import("@/views/dev/conf/index"),
                meta: {
                    title: "全局配置",
                    icon: "global",
                    affix: false,
                },
            },
            {
                path: "static",
                name: "dev-conf-static",
                component: () => import("@/views/dev/conf/static"),
                meta: {
                    title: "静态配置",
                    icon: "config-file",
                    affix: false,
                },
            },
            {
                path: "timezoneconf",
                name: "dev-timezoneconf",
                component: () => import("@/views/omp/titan/timezone"),
                meta: {
                    title: "时区配置",
                    icon: "timezone",
                    affix: false,
                },
            },
            {
                path: "permission",
                name: "dev-permission",
                component: () => import("@/views/omp/titan/permission"),
                meta: {
                    title: "权限配置",
                    icon: "lock",
                    affix: false,
                },
            },
            {
                path: "super",
                name: "omp-account-super",
                component: () => import("@/views/omp/account/super.vue"),
                meta: {
                    title: "特权账号",
                    icon: "protection",
                },
            },
        ],
    },
    {
        path: "/dev/sensor",
        name: "dev-sensor",
        meta: {
            title: "业务配置",
            icon: "sensor",
            parent: "dev",
            permission: "manage_titan_config",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/dev/sensor/preset",
        children: [
            {
                path: "nodetemplate",
                name: "nodetemplate",
                component: () => import("@/views/omp/sensor/nodetemplate"),
                meta: {
                    title: "节点模板",
                    icon: "nodetemplate",
                    affix: false,
                },
            },
            {
                path: "irrigation",
                name: "irrigation",
                component: () => import("@/views/dev/titan/irrigation"),
                meta: {
                    title: "灌溉配置",
                    icon: "irrigation",
                    affix: false,
                },
            },
            {
                path: "preset",
                name: "preset",
                component: () => import("@/views/omp/sensor/preset/index.vue"),
                meta: {
                    title: "属性预设",
                    icon: "preset",
                    affix: false,
                },
            },
            {
                path: "codesnippet",
                name: "codesnippet",
                component: () => import("@/views/omp/sensor/codesnippet"),
                meta: {
                    title: "代码片段",
                    icon: "codesnippet",
                    affix: false,
                },
            },
            {
                path: "crontask",
                name: "crontask",
                component: () => import("@/views/omp/sensor/crontask"),
                meta: {
                    title: "定时任务",
                    icon: "crontask",
                    affix: false,
                },
            },
            {
                path: "indicator",
                name: "indicator",
                component: () => import("@/views/omp/sensor/indicator"),
                meta: {
                    title: "环境指标",
                    icon: "eco",
                    affix: false,
                },
            },
            {
                path: "control",
                name: "control",
                component: () => import("@/views/omp/control/index.vue"),
                meta: {
                    title: "自动控制",
                    icon: "control",
                    affix: false,
                },
            },
            {
                path: "fertilizer",
                name: "fertilizer",
                component: () => import("@/views/dev/titan/fertilizer.vue"),
                meta: {
                    title: "肥料配置",
                    icon: "guide",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/dev/aigrow",
        name: "aigrow",
        meta: {
            title: "AI Grow",
            icon: "aigrow",
            parent: "dev",
            permission: "manage_ai_grow",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: "/dev/aigrow/albums",
        children: [
            {
                path: "albums",
                name: "aigrow-albums",
                component: () => import("@/views/omp/aigrow/albums"),
                meta: {
                    title: "相册管理",
                    icon: "album",
                    affix: false,
                },
            },
            {
                path: "task",
                name: "ripen-task-replay",
                component: () => import("@/views/omp/aigrow/task"),
                // hidden: true,
                meta: {
                    title: "任务队列",
                    icon: "todo",
                    affix: false,
                },
            },
            {
                path: "stat",
                name: "ripen-task-stat",
                component: () => import("@/views/omp/aigrow/stat"),
                // hidden: true,
                meta: {
                    title: "处理统计",
                    icon: "history",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/dev/device",
        name: "dev-device",
        meta: {
            title: "设备管理",
            icon: "device",
            parent: "dev",
            permission: "manage_device",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "dev-device-list",
        },
        children: [
            {
                path: "version",
                name: "dev-device-version",
                component: () => import("@/views/omp/device/version.vue"),
                meta: {
                    title: "加密版本",
                    icon: "protection",
                },
            },
            {
                path: "list",
                name: "dev-device-list",
                component: () => import("@/views/omp/device/index.vue"),
                meta: {
                    title: "全部设备",
                    icon: "device2",
                },
            },
            // {
            //     path: "sim",
            //     name: "omp-device-sim",
            //     component: () => import("@/views/omp/device/sim.vue"),
            //     meta: {
            //         title: "流量续费",
            //         icon: "sim",
            //     },
            // },
        ].concat(deviceRoutes),
    },
    {
        path: "/dev/account",
        name: "account",
        component: () => import("@/layouts/default.vue"),
        redirect: "/dev/account/list",
        meta: {
            title: "系统账号",
            icon: "admin",
            parent: "dev",
            permission: "manage_admin_account",
        },
        children: [
            {
                path: "list",
                name: "account-list",
                component: () => import("@/views/dev/account/list.vue"),
                meta: {
                    title: "账号配置",
                    icon: "users",
                    app: "cms",
                },
            },
            {
                path: "role",
                name: "account-role",
                component: () => import("@/views/dev/account/role.vue"),
                meta: {
                    title: "角色配置",
                    icon: "role",
                    group: 512,
                },
            },
            {
                path: "permission",
                name: "account-permission",
                component: () => import("@/views/dev/account/permission.vue"),
                meta: {
                    title: "权限配置",
                    icon: "group",
                    group: 512,
                },
            },
        ],
    },
    {
        path: "/dev/system",
        name: "dev-system",
        meta: {
            title: "系统管理",
            icon: "system",
            parent: "dev",
            permission: "manage_system",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "dev-system-app",
        },
        children: [
            {
                path: "app",
                name: "dev-system-app",
                component: () => import("@/views/dev/system/app/index.vue"),
                meta: {
                    title: "应用管理",
                    icon: "application",
                    affix: false,
                },
            },
            {
                path: "cache",
                name: "dev-system-cache",
                component: () => import("@/views/dev/system/cache.vue"),
                meta: {
                    title: "缓存管理",
                    icon: "redis",
                    affix: false,
                },
            },
            {
                path: "deploy",
                name: "dev-system-deploy",
                component: () => import("@/views/dev/system/deploy.vue"),
                meta: {
                    title: "前端部署",
                    icon: "deploy",
                    affix: false,
                },
            },
            {
                path: "cdn",
                name: "dev-system-cdn",
                component: () => import("@/views/dev/system/cdn.vue"),
                meta: {
                    title: "内容分发",
                    icon: "cdn",
                    affix: false,
                },
            },
        ],
    },
    {
        path: "/dev/log",
        name: "dev-log",
        meta: {
            title: "系统日志",
            icon: "log",
            parent: "dev",
            permission: "manage_system_log",
        },
        component: () => import("@/layouts/default.vue"),
        redirect: {
            name: "dev-system-app",
        },
        children: [
            {
                path: "service-log",
                name: "cms-service-log",
                component: () => import("@/views/dev/system/service_log/index.vue"),
                meta: {
                    title: "后端日志",
                    icon: "terminal",
                },
            },
            {
                path: "sms-log",
                name: "omp-sms-log",
                component: () => import("@/views/omp/system/sms_log.vue"),
                meta: {
                    title: "短信日志",
                    icon: "sms",
                },
            },
            {
                path: "dev-action-log",
                name: "dev-action-log",
                component: () => import("@/views/dev/system/action_log.vue"),
                meta: {
                    title: "操作日志（后台）",
                    icon: "log",
                },
            },
            {
                path: "omp-action-log",
                name: "omp-action-log",
                component: () => import("@/views/omp/system/action_log.vue"),
                meta: {
                    title: "操作日志（用户）",
                    icon: "log",
                },
            },
            {
                path: "cms-upload-log",
                name: "cms-upload-log",
                component: () => import("@/views/dev/system/upload_log.vue"),
                meta: {
                    title: "上传日志（后台）",
                    icon: "upload",
                },
            },
            {
                path: "omp-upload-log",
                name: "omp-upload-log",
                component: () => import("@/views/omp/system/upload_log.vue"),
                meta: {
                    title: "上传日志（用户）",
                    icon: "upload",
                },
            },
        ],
    },
];
